import axios from "axios";
import { appURL } from "../util/appUrl";

/**
 * @desc fetches names of all registered institution.
 * @return {Array} array of registered institution.
 */

export const fetchAllInstitutions = async () => {
  const res = await axios.get(`${appURL}/institutions`);
  return res?.data;
};
