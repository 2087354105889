import axios from "axios";
import { appURL } from "../util/appUrl";

/**
 * @desc fetches names of all active /inactive registered institution.
 * @return {Array} array of registered institution.
 */

export const fetchInstitutionByActiveStatus = async (param) => {
  // if type param is passed through querykey else default to 1. type ===1 fetches active institutions only
  const typeParam = param?.queryKey[1] ? param.queryKey[1] : 1;
  const res = await axios.get(
    `${appURL}/institutions/active?type=${typeParam}`
  );
  return res?.data;
};
