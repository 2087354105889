import { QueryClient } from "react-query";
import { LoginAndAuthObjectStore } from "../store/AuthAndUserInfo.store";
import { SickleRegistryEntryStore } from "../store/RegistryDataEntryStores/SickleDataEntryStore/SickleEntryStore";
import { StrokeRegistryEntryStore } from "../store/RegistryDataEntryStores/StrokeDataEntryStore/StrokeEntryStore";
import { ThalassaemiaRegistryEntryStore } from "../store/RegistryDataEntryStores/ThalassamiaDataEntryStore/ThalassamiaDataEntryStore";
import XLSX from "xlsx";

// this file contains application settings or temporary test variables

// App routing settings
export const delay = 500;
export const timeout = 20000;

// query client settings
export const queryClientSettings = {
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      cacheTime: Infinity,
      refetchInterval: 3.6e6, //1 hour
      refetchIntervalInBackground: true,
      suspense: false,
      staleTime: Infinity,
    },
    mutations: {
      retry: 0,
    },
  },
};

export const queryClient = new QueryClient(queryClientSettings);

// array of route that are accesed by loggedin user
export const dashboardRoutes = [
  "/dashboard",
  "/create-user",
  "/users",
  "/registry/:registryName",
  "/entry/:registry",
  "/broadcast",
  "/request",
  "/profile",
  "/institutions",
  "/satellites",
  "/register-institution",
  "/register-satellite",
];

// application roles... this should be fetch dynamically

export const appRoles = [
  "ROLE_SYSTEM",
  "ROLE_REGISTRY",
  "ROLE_GROUP",
  "ROLE_CONTRIBUTOR",
];

/**
 * @desc  gathers the id of all the registries a registry admin supercede
 * @return {array} [ids]
 */
export const getIdsOfRegistry = () => {
  const userRole =
    LoginAndAuthObjectStore.getState()?.currentUserState?.myRoles;
  let registryIds = [];
  // eslint-disable-next-line
  userRole?.map((role) => {
    registryIds?.push(role?.registry?.id);
  });
  return registryIds;
};

export const returnHeaderObject = () => {
  const token = LoginAndAuthObjectStore.getState()?.loginState?.token;
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return;
};

export const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["blockquote", "code-block"],
    ["link", "image", "video"],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }], // superscript/subscript
    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    [{ direction: "rtl" }], // text direction

    // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ["clean"], // remove formatting button
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "color",
  "code-block",
  "align",
  "script",
  "background",
];

export const formatRegistryName = (registryName, capitalize) => {
  if (!registryName) {
    return "Add a registry name as param";
  }
  if (capitalize) {
    return (
      registryName.charAt(0).toUpperCase() + registryName.slice(1).toLowerCase()
    );
  }
  return `${registryName.replace(/_/g, " ").toLowerCase()}  `;
};

export const clearEverything = () => {
  StrokeRegistryEntryStore?.getState?.()?.resetStrokeEntryStore?.();
  ThalassaemiaRegistryEntryStore?.getState?.()?.resetThalassaemiaEntryStore?.();
  SickleRegistryEntryStore?.getState?.()?.resetSickleEntryStore?.();
  queryClient.cancelQueries();
  queryClient.clear();
  queryClient.cancelMutations();
  localStorage.clear();
};

export const formatDate = (dateString) => {
  const dateNow = new Date(dateString)
    .toLocaleDateString()
    .replace(",", "")
    .split("/");
  for (var i = 0; i < dateNow.length; i++) {
    if (parseInt(dateNow[i]) < 10) {
      dateNow[i] = "0" + dateNow[i].toString();
    }
  }
  // console.log(dateNow);
  return dateNow;
};

export const formatTableData = (arrayData, key) => {
  const formattedArray = [];
  arrayData?.forEach((element) => {
    let singleObject = {
      ...element[key],
      parentId: element[key]?.id,
      id: element?.id,
      version: element?.version,
      createdDate: element?.createdDate,
      lastModifiedDate: element?.lastModifiedDate,
      approve: element?.approve,
      uniqueIdentifier: element?.uniqueIdentifier,
    };
    formattedArray.push(singleObject);
  });
  return formattedArray;
};

export const ExportToExcel = (dataPanel, fileName) => {
  let filename = fileName || "reports.xlsx";

  var ws = XLSX.utils.json_to_sheet(dataPanel);
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "People");
  XLSX.writeFile(wb, filename);
};
