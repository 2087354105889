import axios from "axios";
import { appURL } from "../util/appUrl";
import { returnHeaderObject } from "../util/miscellaneous";
/**
 * @desc fetches all registries that there is in the application.
 * @return {Object} object of registered institution.
 */

export const fetchAllRegistries = async () => {
  const allRegistries = await axios({
    method: "get",
    url: `${appURL}/registries?type=2`,
    headers: returnHeaderObject(),
  });
  return allRegistries?.data?._embedded?.registries;
};
