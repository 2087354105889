import { createMuiTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Theme configuration for material ui
export const MUITheme = createMuiTheme({
  props: {
    MuiInput: {
      disableUnderline: true,
    },
    MuiTypography: {
      color: "primary",
    },
    MuiButton: {
      color: "primary",
    },
    "MuiToolbar-root": {
      backgroundColor: "#002c85",
    },
  },

  typography: {
    fontFamily: "Raleway",
  },

  palette: {
    primary: {
      main: "#002c85",
    },
    secondary: {
      main: "#002c85",
    },
  },

  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "var(--main-color)",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    "MuiToolbar-root": {
      backgroundColor: "#002c85",
    },
    MuiPickersDay: {
      day: {
        color: "var(--main-color)",
      },
      daySelected: {
        backgroundColor: "var(--main-color)",
      },
      dayDisabled: {
        color: "#ccc",
      },
      current: {
        color: "var(--main-color)",
      },
    },
    MuiPickersModal: {
      //   dialogAction: {
      //     color: "var(--main-color)",
      //   },
    },

    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
      },
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: "100%",
    // boxShadow:
    //   "inset 1px 1px 4px rgba(0, 0, 0, 0.24), inset -1px -1px 2px rgba(0, 0, 0, 0.12)",
    boxShadow: "inset 2px 2px 4px #d1d9e6,inset -2px -2px 4px #f9f9f9",
  },

  appBar: {
    position: "relative",
    backgroundColor: "var(--main-color)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  keyboard: {
    // boxShadow:
    //   "inset 1px 1px 4px rgba(0, 0, 0, 0.24), inset -1px -1px 2px rgba(0, 0, 0, 0.12)",
    boxShadow: "inset 2px 2px 4px #d1d9e6,inset -2px -2px 4px #f9f9f9",
    width: "100%",
  },
  "&.Mui-focused": {
    border: "0px ",
  },
  label: {
    color: "#aaa",
    fontFamily: "Raleway",
    fontWeight: 500,
    paddingLeft: "10px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  MenuItem: {
    // fontWeight: "bold",
    color: "var(--main-color)",
    fontFamily: "var(--main-font)",
    fontSize: "16px",
    // padding: "5px",
  },
  select: {
    // fontWeight: "bold",
    color: "var(--main-color)",
    fontFamily: "var(--main-font)",
    fontSize: "16px",
    // padding: "5px",
    // backgroundColor:"red"
  },
}));
