import axios from "axios";
import { appURL } from "../util/appUrl";

/**
 * @desc fetches names of all active/inactive registered satellites.
 * @return {Array} array of acive/inactive registered satellites.
 */

export const fetchRegistriesByActiveStatus = async (param) => {
  // if type param is passed through querykey else default to 1. type ===1 fetches active satellites only
  const typeParam = param?.queryKey[1] ? param.queryKey[1] : 1;
  const res = await axios.get(`${appURL}/registries?type=${typeParam}`);
  return res?.data?._embedded?.registries;
};
