const findEighteenYearsAgo = new Date().setFullYear(
  new Date().getFullYear() - 18
);

export const screenA = {
  gender: "",
  dateOfBirth: findEighteenYearsAgo,
  initial: "",
  ageAtDiagnosis: "",
  dateOfEnrollment: new Date(),
  surgeryIndicated: "",
  surgeryDone: "",
  weight: "",
  height: "",
  education: "",
  tribe: "",
  occupation: "",
};
export const screenB = {
  glasgowComaScore: "",
  systolicBp1: "",
  systolicBp2: "",
  // averageSystolicBp: "",
  // averageDiastolicBp: "",
  diastolicBp1: "",
  diastolicBp2: "",
  temperature: "",
  pulseRate: "",
  respiratoryRate: "",
};
export const screenC = {
  urinaryTractInfection: "",
  respiratoryTractInfection: "",
  deepVeinThrombosis: "",
  dysphagia: "",
  urinaryIncontinence: "",
  facialIncontinence: "",
  pressureSores: "",
  fractures: "",
  spasticity: "",
  contractures: "",
  dementia: "",
  falls: "",
  delirium: "",
  shoulderPain: "",
  centralPostStrokePain: "",
  depression: "",
  anxiety: "",
  emotionalism: "",
  fatigue: "",
  earlyPostStrokeSeizures: "",
  latePostStrokeSeizures: "",
};
export const screenD = {
  fastingBloodSugar: null,
  triglycrides: null,
  totalCholesterol: null,
  hdlCholesterol: null,
  ldlCholesterol: null,
  soduim: null,
  potassium: null,
  chloridePlasma: null,
  chloridecsf: null,
  bicarbonate: null,
  urea: null,
  creatinineMale: null,
  creatinineFemale: null,
  plateletCount: null,
  prothrombine: null,
  activatedPartialThrombosPlastintime: "",
};
export const screenE = {
  sideOfTheBodyAffected: "",
  limbWeakness: "",
  numbnessAndTingling: "",
  facialWeaknessAndDeviationOfTheMouth: "",
  headache: "",
  seizure: "",
  vomitting: "",
  lossOfConsciusness: "",
  neckPain: "",
  aphasia: "",
  doubleVision: "",
  unsteadyGait: "",
  diziness: "",
  vertigo: "",
};
export const screenF = {
  hypertension: "",
  diabetes: "",
  dyslipidaemia: "",
  obesity: "",
  sendentryLifeStyle: "",
  ocps: "",
  artrialFibrillation: "",
  coronaryArteryDisease: "",
  rheumaticHeartDisease: "",
  cartoidstenosis: "",
  previousTIA: "",
  hiv: "",
  drugAddiction: "",
  alchoholic: "",
  smoking: "",
};
export const screenG = {
  strokeLocation: "",
};

export const screenH = {
  discharge: "",
  dateOfDischarge: new Date(),
  dama: "",
  dateOfDama: new Date(),
  died: "",
  dateOfDeath: new Date(),
};
export const screenI = {
  dateOfStrokeOnset: new Date(),
  timeOfStrokeOnset: new Date(),
  dateOfPresentationatUPTH: new Date(),
  timeOfPresenatationonAECard: new Date(),
  isFirsteverStroke: "",
  dateOfPreviousStroke: new Date(),
  numberOfPreviousStroke: null,
};
export const screenJ = {
  hypertension: "",
  diabetes: "",
  stroke: "",
  dementia: "",
};
export const screenK = {
  ranking: null,
};
