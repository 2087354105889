import React from "react";
import { Route, Redirect } from "react-router-dom";
import { LoginAndAuthObjectStore } from "../../store/AuthAndUserInfo.store";

export default function PrivateRoute({ component: Component, ...rest }) {
  const loginState = LoginAndAuthObjectStore((state) => state.loginState);
  const currentUserState = LoginAndAuthObjectStore(
    (state) => state.currentUserState
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        loginState !== null || currentUserState !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}
