import React from "react";
import CustomButton from "../button/CustomButton";
import "./Suspense.scss";

export default function LoadingSuspense(props) {
  if (props.error) {
    return (
      <div className="suspense-container uk-animation-fade ">
        <div className="suspense-text">
          <p className="font-weight-bold text-danger mb-5 uk-animation-fade">
            Failed to load due to poor internet connection
          </p>
        </div>
        <div className="suspense-button uk-animation-fade">
          <CustomButton
            action={() => window.location.reload()}
            text="Reload afresh"
          />
        </div>
      </div>
    );
  } else if (props.timedOut && !props.error) {
    return (
      <div className="suspense-container uk-animation-fade ">
        <div className="suspense-text">
          <p className="font-weight-bold mb-5 uk-animation-fade">
            Taking time to load ...
          </p>
        </div>
        <div className="suspense-button uk-animation-fade">
          <CustomButton action={props.retry} text="Reload afresh" />
        </div>
      </div>
    );
  } else if (props.pastDelay && !props.error) {
    return (
      <div className="uk-animation-fade suspense-container">
        <div className="loadingio-spinner-ellipsis-nwpk69546c9">
          <div className="ldio-i9t3xk8hcnb">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
