import LoadingSuspense from "../components/suspense/Suspense";
import { delay, timeout } from "../util/miscellaneous";
import LoadableVisibility from "react-loadable-visibility/react-loadable";

// All pages to be used in App.js should be configured here (lazy loading suspense etc)

export const Home = LoadableVisibility({
  loader: () => import("../pages/home/Home"),
  loading: LoadingSuspense,
  delay: delay,
  timeout: timeout,
});

export const Error404 = LoadableVisibility({
  loader: () => import("../pages/error404/Error404"),
  loading: LoadingSuspense,
  delay: delay,
  timeout: timeout,
});
export const GlobalFetches = LoadableVisibility({
  loader: () => import("../components/globalfetches/GlobalFetches"),
  loading: LoadingSuspense,
  delay: delay,
  timeout: timeout,
});

export const Signup = LoadableVisibility({
  loader: () => import("../pages/signup/Signup"),
  loading: LoadingSuspense,
  delay: delay,
  timeout: timeout,
});

export const Login = LoadableVisibility({
  loader: () => import("../pages/login/Login"),
  loading: LoadingSuspense,
  delay: delay,
  timeout: timeout,
});

export const Dashboard = LoadableVisibility({
  loader: () => import("../pages/dashboard/Dashboard"),
  loading: LoadingSuspense,
  delay: delay,
  timeout: timeout,
});

export const Info = LoadableVisibility({
  loader: () => import("../pages/info/Info"),
  loading: LoadingSuspense,
  delay: delay,
  timeout: timeout,
});
export const RegistryPage = LoadableVisibility({
  loader: () => import("../pages/registryPage/RegistryPage"),
  loading: LoadingSuspense,
  delay: delay,
  timeout: timeout,
});
