import axios from "axios";
import { appURL } from "../util/appUrl";

/**
 * @desc fetches names of all registered satellites.
 * @return {Array} array of registered satellites.
 */

export const fetchAllSatellites = async () => {
  const res = await axios.get(`${appURL}/satellites`);
  return res?.data;
};
