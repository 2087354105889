import React from "react";
import "./CustomButton.scss";
import PropTypes from "prop-types";

export default class CustomButton extends React.Component {
  static propTypes = {
    /**
     * type of button
     */
    type: PropTypes.string,
    /**
     * Action when pressed.
     */
    action: PropTypes.func,
    /**
     * Action when pressed.
     */
    disabled: PropTypes.bool,
    /**
     * Text for the button.
     */
    text: PropTypes.string,
  };

  static defaultProps = {
    type: "button",
    action: () => {},
    text: "hello",
    disabled: false,
  };

  render() {
    const { text, action, type, disabled, ...rest } = this.props;
    return (
      <div className="custom-button-container">
        <button
          className="btn-custom draw-border"
          onClick={action}
          type={type}
          disabled={disabled}
          {...rest}
        >
          {text}
        </button>
      </div>
    );
  }
}
