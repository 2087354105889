import create from "zustand";
import { persist2 } from "../../storeHelper";
import { SickleRegistryEntryStoreKey } from "../../storeLocalStorageNames";
import {
  screenA,
  screenB,
  screenC,
  screenD,
  screenE,
  screenF,
  screenG,
} from "./SickleEntryStorehelper";

export const SickleRegistryEntryStore = create(
  persist2(SickleRegistryEntryStoreKey, (set, get) => ({
    currentScreen: 0,
    completedScreens: [],
    currentTabView: 0,
    currentTableView: 0,
    screenA,
    screenB,
    screenC,
    screenD,
    screenE,
    screenF,
    screenG,

    removeFromCompletedScreen: (screenNumber) => {
      let filteredArrrayCompletedScreens = get()?.completedScreens?.filter?.(
        (item) => {
          return item !== screenNumber;
        }
      );
      return set((state) => {
        state.completedScreens = state.completedScreens.includes(screenNumber)
          ? [...filteredArrrayCompletedScreens]
          : [...state.completedScreens];
      });
    },

    resetCompletedScreen: () => {
      return set((state) => {
        state.completedScreens = [];
      });
    },

    clearAllScreens: () => {
      set((state) => {
        state.screenA = screenA;
        state.screenB = screenB;
        state.screenC = screenC;
        state.screenD = screenD;
        state.screenE = screenE;
        state.screenF = screenF;
        state.screenG = screenG;
      });
    },

    resetScreenA: () => {
      return set((state) => {
        state.screenA = screenA;
      });
    },

    addCompletedScreen: (screenNumber) => {
      return set((state) => {
        state.completedScreens = state.completedScreens.includes(screenNumber)
          ? [...state.completedScreens]
          : [...state.completedScreens, screenNumber];
      });
    },

    setScreenAData: (data) => {
      return set((state) => {
        state.screenA = { ...data };
      });
    },
    setScreenBData: (data) => {
      return set((state) => {
        state.screenB = { ...data };
      });
    },
    setScreenCData: (data) => {
      return set((state) => {
        state.screenC = { ...data };
      });
    },
    setScreenDData: (data) => {
      return set((state) => {
        state.screenD = { ...data };
      });
    },
    setScreenEData: (data) => {
      return set((state) => {
        state.screenE = { ...data };
      });
    },
    setScreenFData: (data) => {
      return set((state) => {
        state.screenF = { ...data };
      });
    },
    setScreenGData: (data) => {
      return set((state) => {
        state.screenG = { ...data };
      });
    },

    setCurrentTabView: (newIndex) => {
      set((state) => {
        state.currentTabView = newIndex;
      });
    },
    setCurrentTableView: (newIndex) => {
      set((state) => {
        state.currentTableView = newIndex;
      });
    },

    setNextScreen: (screenNumber) => {
      return set((state) => {
        state.currentScreen = screenNumber;
      });
    },
    setPrevScreen: (screenNumber) => {
      if (get()?.currentScreen < 0) {
        return;
      }
      return set((state) => {
        state.currentScreen = screenNumber;
      });
    },

    resetSickleEntryStore: () => {
      set((state) => {
        state.currentScreen = 0;
        state.currentTabView = 0;
        state.currentTableView = 0;
        state.completedScreens = [];
        state.screenA = screenA;
        state.screenB = screenB;
        state.screenC = screenC;
        state.screenD = screenD;
        state.screenE = screenE;
        state.screenF = screenF;
        state.screenG = screenG;
      });
    },
  }))
);
