// query string key for fetching the number of institutions
export const fetchHomeStatKey = "FETCH_HOME_STAT";

// query string key for fetching names of all registered institutions
export const fetchAllInstitutionsKey = "FETCH_ALL_INSTITUTIONS";

// query string key for fetching names of all registered institutions
export const fetchInstitutionByActiveStatusKey =
  "FETCH_INSTITUTIONS_BY_ACTIVE_STATUS";

// query string key for fetching names of all active/inactive satellites
export const fetchSatellitesByActiveStatusKey =
  "FETCH_SATELLITES_BY_ACTIVE_STATUS";

// query string key for fetching a single institution
export const fetchSingleInstitutionKey = "FETCH_SINGLE_INSTITUTION";

// query string key for fetching all registries a logged-in user belongs to
export const fetchCurrentUserRegistriesKey = "FETCH_CURRENT_USER_REGISTRIES";

// query string key for fetching a single registry
export const fetchSingleRegistryKey = "FETCH_SINGLE_REGISTRY";

// query string key for fetching all registries available in the application
export const fetchAllRegistriesKey = "FETCH_ALL_REGISTRIES";

// query string key for fetching all registries a registry admin oversee
export const fetchAllRegistriesAdminKey = "FETCH_ALL_REGISTRIES_ADMIN";

// query string key for fetching all registries currently active
export const fetchRegistriesByActiveStatusKey = "FETCH_ALL_REGISTRIES_ACTIVE";

// query string key for fetching all users . super admin
export const fetchUsersSuperAdminKey = "FETCH_ALL_USERS_SUPER_ADMIN";

// query string key for fetching all users . super admin
export const fetchAllUsersFromAllRegistriesSuperAdminKey =
  "FETCH_ALL_USERS_FROM_ALL_REG_SUPER_ADMIN";

// query string key for fetching all users . registry admin
export const fetchUsersRegistryAdminKey = "FETCH_ALL_USERS_REGISTRY_ADMIN";

// query string key for fetching all users . group admin
export const fetchUsersGroupAdminKey = "FETCH_ALL_USERS_GROUP_ADMIN";

// query string key for logging in users
export const loginUserKey = "LOGIN_USER";

// query string key for siging up users
export const signupUserKey = "SIGNUP_USER";

// query string key for logging in users
export const fetchUserInfoKey = "FETCH_USER_INFO";

// query string key for creating a new contributor
export const createContributorKey = "CREATE_CONTRIBUTOR";

// query string key fetching all users
export const fetchAllUsersKey = "FETCH_ALL_USERS";

// query string key for editing a selected institution for system admin
export const roleSuperEditInstitutionKey = "ROLE_SUPER_EDIT_INSTITUTION";

// query string key for editing a selected satellite
export const editSatelliteKey = "EDIT_SATELLITE";

// query string key for editing a selected satellite
export const editRegistryKey = "EDIT_REGISTRIES";

// query string key for editing a selected institution for group admin
export const roleGroupEditInstitutionKey = "ROLE_SUPER_EDIT_INSTITUTION";

// query string key for editing a selected user
export const editSelectedUserKey = "EDIT_SELECTED_USER";

// query string key for registering an institution
export const registerInstitutionKey = "REGISTER_INSTITUTION";

// query string key for registering a satelliet
export const registerSatelliteKey = "REGISTER_SATELLITE";

// query string key for toggling approval status of an institution
export const toggleInstitutionApprovalKey = "TOGGLE_INSTITUTION_APPROVAL";

// query string key for toggling approval status of an registry
export const toggleRegistryApprovalKey = "TOGGLE_REGISTRY_APPROVAL";

// query string key for toggling approval status of a satellite
export const toggleSatelliteApprovalKey = "TOGGLE_SATELLITE_APPROVAL";

// query string key for toggling approval status of a user
export const toggleUserApprovalKey = "TOGGLE_USER_APPROVAL";

// query string key for deleting an institution
export const deleteInstitutionKey = "DELETE_INSTITUTION";

// query string key for deleting an user
export const deleteUserKey = "DELETE_USER";

// query string key for deleting an registry page
export const deleteRegistryPageKey = "DELETE_REGISTRY_PAGE";

// query string key for deleting a satellite
export const deleteSatelliteKey = "DELETE_SATELLITE";

// query string key for getting the institutions a group admin belongs to
export const getRoleGroupInstitutionsBelongedKey =
  "GET_ROLE_GROUP_INSTITUTIONS";

// query string key for getting the institutions a group admin belongs to
export const fetchAllSatellitesKey = "FETCH_ALL_SATELLITES";

// query string key for posting stroke screen a data
export const postStrokeScreenA = "POST_STROKE_SCREENA";

export const postSickleScreenA = "POST_SICKLE_SCREENA";

export const postThalassaemiaScreenA = "POST_THALA_SCREENA";

export const fetchAllStrokeDataKey = "FETCH_ALL_STROKE_DATA";

export const fetchAllSickleDataKey = "FETCH_ALL_SICKLE_DATA";

export const fetchAllThalaDataKey = "FETCH_ALL_THALA_DATA";

export const deleteStrokeEntryKey = "DELETE_STROKE_DATA";

export const deleteSickleEntryKey = "DELETE_SICKLE_DATA";

export const deleteThalaEntryKey = "DELETE_THALA_DATA";

export const fetchStrokeMaleBasedStatKey = "FETCH_STROKE_MALE_BASED_STAT";

export const fetchSickleMaleBasedStatKey = "FETCH_SICKLE_MALE_BASED_STAT";

export const fetchThalaMaleBasedStatKey = "FETCH_THALA_MALE_BASED_STAT";

export const fetchStrokeMonthlySubmissionStatKey =
  "FETCH_STROKE_MONTHLY_SUBMISSION_STAT";

export const fetchSickleMonthlySubmissionStatKey =
  "FETCH_SICKLE_MONTHLY_SUBMISSION_STAT";

export const fetchThalaMonthlySubmissionStatKey =
  "FETCH_THALA_MONTHLY_SUBMISSION_STAT";

export const fetchStrokeByInstitutionStatKey =
  "FETCH_STROKE_BY_INSTITUTION_STAT";

export const fetchSickleByInstitutionStatKey =
  "FETCH_SICKLE_BY_INSTITUTION_STAT";

export const fetchThalaByInstitutionStatKey = "FETCH_THALA_BY_INSTITUTION_STAT";

export const editStrokeSetionsKey = "EDIT_STROKE_SECTIONS";

export const editThalaSetionsKey = "EDIT_THALA_SECTIONS";

export const editSickleSetionsKey = "EDIT_SICKLE_SECTIONS";

export const fetchRoleRequestsSystemkey = "FETCH_ROLE_REQUESTS_SYSTEM";

export const fetchRoleRequestsRegistrykey = "FETCH_ROLE_REQUESTS_REGISTRY";

export const approveRoleRequestkey = "APPROVE_ROLE_REQUEST";

export const disapproveRoleRequestkey = "DISAPPROVE_ROLE_REQUEST";

export const roleRequestContributorkey = "ROLE_REQUEST_CONTRIBUTOR";

export const roleRequestGroupAdminkey = "ROLE_REQUEST_CONTRIBUTOR";
