import { useQuery } from "react-query";
import {
  fetchAllInstitutionsKey,
  fetchInstitutionByActiveStatusKey,
  fetchAllSatellitesKey,
  fetchSatellitesByActiveStatusKey,
  fetchAllRegistriesKey,
  fetchRegistriesByActiveStatusKey,
} from "../../util/queryKeys";
import { fetchAllInstitutions } from "../../service/fetchAllInstitutions.service.";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { fetchInstitutionByActiveStatus } from "../../service/fetchInstitutionByActiveStatus.service";
import { fetchAllSatellites } from "../../service/fetchAllSatellites.service";
import { fetchSatellitesByActiveStatus } from "../../service/fetchSatellitesByActiveStatus.service";
import { fetchAllRegistries } from "../../service/fetchAllRegistries.service";
import { fetchRegistriesByActiveStatus } from "../../service/fetchRegistriesByActiveStatus.service";
import { LoginAndAuthObjectStore } from "../../store/AuthAndUserInfo.store";

export default function GlobalFetches() {
  const userRoles = LoginAndAuthObjectStore(
    (state) => state?.currentUserState?.itsRoles
  );
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useQuery(fetchAllInstitutionsKey, fetchAllInstitutions);
  useQuery(fetchAllRegistriesKey, fetchAllRegistries, {
    enabled: userRoles?.includes("ROLE_SYSTEM") ? true : false,
  });
  useQuery(fetchInstitutionByActiveStatusKey, fetchInstitutionByActiveStatus);
  useQuery(fetchAllSatellitesKey, fetchAllSatellites);
  useQuery(fetchSatellitesByActiveStatusKey, fetchSatellitesByActiveStatus);
  useQuery(fetchRegistriesByActiveStatusKey, fetchRegistriesByActiveStatus);

  return null;
}
