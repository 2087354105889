const findEighteenYearsAgo = new Date().setFullYear(
  new Date().getFullYear() - 18
);

export const screenA = {
  dateOfBirth: findEighteenYearsAgo,
  gender: "",
  dateOfRegistration: new Date(),
  maritalStatus: "",
  levelOfEducation: "",
  employement: "",
  numberOfSibling: "",
  numberOfChildren: "",
  numberOfChildrenWithBetaThal: "",
  numberOfSiblingsAffectedByBetaThal: "",
};
export const screenB = {
  previousHistoryOfJaundice: "",
  currentHistoryOfJaundice: "",
  previousHistoryOfHaemoglobinuria: "",
  currentHistoryOfHaemoglobinuria: "",
  numberOfAbortionsIfFemale: "",
  spleenSize: "",
  liverSize: "",
};
export const screenC = {
  doYouHaveGallstoneOrBefore: "",
  doYouHaveThromboembolismOrBefore: "",
  doYouHaveSplenectomyOrBefore: "",
  doYouHaveOsteomyelitisOrBefore: "",
  doYouHaveSvascularNecrosisOrBefore: "",
  doYouHaveStrokeOrBefore: "",
  doYouHaveChronicLegUlcerOrBefore: "",
  doYouHavePriapismOrBefore: "",
  doYouHaveHypertensionOrBefore: "",
  doYouHavePulmunaryHypertensionOrBefore: "",
  doYouHaveCardiomyopathyOrBefore: "",
  doYouHaveChronicKidneyDiseaseOrBefore: "",
  doYouHaveHepatopathyOrBefore: "",
  doYouHaveDiabetesOrBefore: "",
  doYouHaveAcuteChestSyndromeOrBefore: "",
  doYouHaveOpoidAbuseOrBefore: "",
  agetAtGallstone: "",
  agetAtThromboembolism: "",
  agetAtSplenectomy: "",
  agetAtOsteomyelitis: "",
  agetAtAvascularNecrosis: "",
  agetAtStroke: "",
  agetAtChronicLegUlcer: "",
  agetAtPriapism: "",
  agetAtHypertension: "",
  agetAtPulmunaryHypertension: "",
  agetAtCardiomyopathy: "",
  agetAtChronicKidneyDisease: "",
  agetAtHepatopathy: "",
  agetAtDiabetes: "",
  agetAtAcuteChestSyndrome: "",
  agetAtOpoidAbuse: "",
};
export const screenD = {
  ageAtFirstPresentation: "",
  ageAtDiagnosis: "",
  mch: "",
  mcv: "",
  rdw: "",
  hbA2: "",
  hbF: "",
  serumFerritin: "",
  pcvAtDiagnosis: "",
  plateletCountAtDiagnosis: "",
  alphaThalassaemiaStatus: "",
  hbGenotype: "",
};
export const screenE = {
  cd2: "",
  ivs216: "",
  ivs274: "",
  ivs2666: "",
  xmnl: "",
  betaGlobinHaplotype: "",
};
export const screenF = {
  dateOfDeath: new Date(),
  ageAtDeath: "",
  diagnosisAtDeath: "",
  causeOfDeath: "",
  postMortemPerformed: "",
  postMortemDiagnosis: "",
};
export const screenG = {
  polycythaemia: "",
  persistentThrombocytopaenia: "",
  others: "",
};

export const screenH = {
  ageAtMenarche: "",
  contraceptive: "",
  parity: "",
  ageAtFirstDelivery: "",
  historyOfAmenorrheaWithoutPregnancy: "",
  historyOfInfertility: "",
};
export const screenI = {
  haveYouEverBeenTransfused: "",
  numberOfUnitTransfusedTillDate: "",
  ageAtFirstTransfusion: "",
  indicationForFirstTransfusionApartFromAnaemia: "",
  transfusionDependentAtleastOneUnitPerTwoWeek: "",
  howLongHasBeenTransfusionDependent: "",
};
