const findEighteenYearsAgo = new Date().setFullYear(
  new Date().getFullYear() - 18
);

export const screenA = {
  dateOfRegistration: new Date(),
  dateOfBirth: findEighteenYearsAgo,
  gender: "",
  maritalStatus: "",
  levelOfEducation: "",
  employement: "",
  numberOfSibling: "",
  numberOfSiblingsAffectedBySCD: "",
  numberOfChildren: "",
  numberOfChildrenAffectedBySCD: "",
};
export const screenB = {
  doYouHaveGallStoneNowOrBefore: "",
  doYouHaveOsteomyelitisNowOrBefore: "",
  doYouHaveAvascularNecrosisNowOrBefore: "",
  doYouHaveChronicLegUlcerNowOrBefore: "",
  doYouHavePriapismNowOrBefore: "",
  doYouHaveStrokeNowOrBefore: "",
  doYouHaveHypertensionNowOrBefore: "",
  doYouHavePulmonaryHypertensionNowOrBefore: "",
  doYouHaveChronicKidneyDiseaseNowOrBefore: "",
  doYouHaveHepatopathyNowOrBefore: "",
  doYouHaveDiabetesNowOrBefore: "",
  doYouHaveAcuteChestSyndromeNowOrBefore: "",
  doYouHaveRetinalDiseaseNowOrBefore: "",
  doYouHaveOpoidAbuseNowOrBefore: "",
  agetAtDiagnosisOfGallstone: "",
  ageAtDiagnosisOfOsteomyelitis: "",
  ageAtDiagnosisOfAvascularNecrosis: "",
  ageAtDiagnosisOfStroke: "",
  ageAtDIagnosisOfChroniclegUlcer: "",
  ageAtDiagnosisOfPriapism: "",
  ageAtDiagnosisOfHypertension: "",
  ageAtDiagnosisOfPulmunaryHypertension: "",
  ageAtDiagnosisOfChronicKidneyDisease: "",
  ageAtDiagnosisOfHepatopathy: "",
  ageAtDiagnosisOfDiabetes: "",
  ageAtDiagnosisOfAcuteChestSyndrome: "",
  ageAtDiagnosisOfRetinalDisease: "",
  ageAtDiagnosisOfOpoidAbuse: "",
};
export const screenC = {
  ageAtDiagnosis: "",
  genotype: "",
  alphaThalassaemiaStatus: "",
  hbfLevel: "",
  hbfa2Level: "",
  serumFerritin: "",
  steadyStateOfHaematocrit: "",
  mch: "",
};
export const screenD = {
  historyOfPriapismMale: "",
  ageAtFirstEpisodeMale: "",
  numberOfEpisodesYearlyMale: "",
  haveYouEverHadSurgeryForPriapismMale: "",
  yourWifeEverHadDiffcultyGettingPregnantMale: "",
};
export const screenE = {
  ageAtFirstDeliveryFemale: "",
  contraceptiveFemale: "",
  parityFemale: "",
  historyOfAmenorrheaWithoutPregnancyFemale: "",
  historyOfInfertilityFemale: "",
  ageAtMenarcheFemale: "",
};
export const screenF = {
  doYouTakeFolicAcidDaily: "",
  doYouTakePlaudrineDaily: "",
  doYouTakeHydroxyureaDaily: "",
  doYouTakeNiprisanDaily: "",
  doYouTakeCiklavitDaily: "",
  otherHerbalMixtureYouTakeDaily: "",
};
export const screenG = {
  dateOfDeath: new Date(),
  ageAtDeath: "",
  diagnosisAtDeath: "",
  causesOfDeath: "",
  wasPostMortemperformed: "",
  postMortemDiagnosis: "",
};
