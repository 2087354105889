import create from "zustand";
import { clearEverything } from "../util/miscellaneous";
import { persist2 } from "./storeHelper";

export const LoginAndAuthObjectStore = create(
  persist2("user", (set, get) => ({
    loginState: null,
    currentUserState: null,
    isLoginSessionExpired: true,
    currentUserRegistries: null,
    newVersion: false,

    loginSuccess: (userInfoObject) => {
      return set((state) => {
        state.loginState = userInfoObject?.login;
        state.isLoginSessionExpired = false;
        state.currentUserRegistries =
          userInfoObject?.userInfo?.currentUserRegistries;
        state.currentUserState = userInfoObject.userInfo;
      });
    },

    setNewVersion: (status) => {
      return set((state) => {
        state.newVersion = status;
      });
    },

    setIsLoginSessionExpired: (status) => {
      return set((state) => {
        state.isLoginSessionExpired = status;
      });
    },

    resetAuth: () => {
      clearEverything();
      return set((state) => {
        state.loginState = null;
        state.isLoginSessionExpired = true;
        state.currentUserRegistries = null;
        state.currentUserState = null;
      });
    },
    refreshCurrentUserInfo: (registries) => {
      return set((state) => {
        state.currentUserState = registries;
      });
    },
  }))
);
