import { ReactQueryDevtools } from "react-query/devtools";
import { Route, Switch } from "react-router-dom";
import React from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { dashboardRoutes, queryClient } from "./util/miscellaneous";
import { QueryClientProvider } from "react-query";
import { MUITheme } from "./constants/MUITheme";
import {
  Home,
  Dashboard,
  Signup,
  Login,
  Error404,
  Info,
  RegistryPage,
} from "./constants/pagesImport";
import PrivateRoute from "./components/privateRoute/PrivateRoute";
import SignupAndLoginCustomRoute from "./components/SignupAndLoginCustomRoute/SignupAndLoginCustomRoute";
import GlobalFetches from "./components/globalfetches/GlobalFetches";

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={MUITheme}>
          <Switch>
            <Route exact strict path="/" component={Home} />
            <Route exact strict path="/info" component={Info} />
            <Route
              exact
              strict
              path="/info/registry/:registryId"
              component={RegistryPage}
            />
            <SignupAndLoginCustomRoute
              exact
              strict
              path="/signup"
              component={Signup}
            />
            <SignupAndLoginCustomRoute
              exact
              strict
              path="/login"
              component={Login}
            />
            <PrivateRoute
              exact
              strict
              path={dashboardRoutes}
              component={Dashboard}
            />
            <Route component={Error404} />
          </Switch>
        </MuiThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default App;
